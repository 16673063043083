import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  FormLabel,
  Heading,
  Input,
  Text,
  ValidationMessage,
} from '@newday/core';
import axios from 'axios';
import React, { useContext } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';
import { useApplicationId } from '../../app';
import { AuthContext } from '../../app/auth-provider';
import {
  Alert,
  BalloonBox,
  ContentWrapper,
  DateOfBirthInput,
  Loading,
  ResponsivePageLayout,
} from '../../components';
import { ApplicationReference } from '../../features';
import {
  Events,
  FormActions,
  FormNames,
  gtmTrackEvent,
} from '../../utils/gtm-track-event';
import { useSaveAndReturn } from './queries';
import { useInitialRoute } from '../../shared/queries';

const isErrorStatus401 = (error) =>
  axios.isAxiosError(error) && error.response?.status === 401;

const schema = z.object({
  postcode: z.string().min(1, { message: 'Please enter your postcode' }),
  dateOfBirth: z.object({
    day: z.string().min(1),
    month: z.string().min(1),
    year: z.string().min(4),
  }),
});

type FormSchemaType = z.infer<typeof schema>;

export enum SaveAndReturnFormFields {
  Postcode = 'postcode',
  DateOfBirth = 'dateOfBirth',
}

export enum SaveAndReturnFormFieldErrors {
  PostcodeError = 'Please enter a valid date',
  DateOfBirthError = 'Please enter your postcode',
}

export const SaveAndReturnPage: React.FC = () => {
  const { setIsAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();
  const { applicationId } = useApplicationId();
  const { control, handleSubmit, setValue } = useForm<FormSchemaType>({
    resolver: zodResolver(schema),
    defaultValues: {
      postcode: '',
    },
  });

  const {
    mutate,
    isLoading: isSaveAndReturnLoading,
    isSuccess: isSaveAndReturnSuccess,
    error,
  } = useSaveAndReturn(applicationId);

  const {
    data: redirectData,
    isSuccess: isRedirectSuccess,
    isLoading: isRedirectLoading,
  } = useInitialRoute(
    applicationId,
    isSaveAndReturnSuccess,
    'redirect-with-eligibility-post'
  );

  const onSubmit: SubmitHandler<FormSchemaType> = ({
    dateOfBirth: { day, month, year },
    postcode,
  }) => {
    mutate(
      {
        postcode,
        dateOfBirth: {
          day,
          month,
          year,
        },
      },
      {
        onSuccess: () =>
          gtmTrackEvent({
            event: Events.FORM_CTA,
            form_name: FormNames.SAVE_AND_RETURN,
            form_action: FormActions.CLICK_CTA,
            link_text: 'log into my application',
          }),
      }
    );
  };

  React.useEffect(() => {
    if (isRedirectSuccess && redirectData) {
      setIsAuthenticated(true);
      navigate(redirectData.data);
    }
  }, [isRedirectSuccess, navigate, setIsAuthenticated, redirectData]);

  return (
    <>
      <ResponsivePageLayout>
        <ApplicationReference />
        <BalloonBox fullWidth>
          <>
            <Heading fontSize="3xl" fontWeight="bold">
              Welcome back
            </Heading>
            <Text fontWeight="semibold" mt={2}>
              To see the status of your loan application, log in by completing
              your details below.
            </Text>
          </>
        </BalloonBox>
        <ContentWrapper maxContentWidth="md" py={4}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {isErrorStatus401(error) && (
              <Box pb="2rem">
                <Alert
                  title="That doesn't look right"
                  message="The details you entered don't match your application. Please try again."
                />
              </Box>
            )}
            <Box>
              <Controller
                name={SaveAndReturnFormFields.DateOfBirth}
                control={control}
                render={({ field, fieldState: { error } }) => {
                  if (error) {
                    gtmTrackEvent({
                      event: Events.FORM_ERROR,
                      form_field_error:
                        SaveAndReturnFormFieldErrors.DateOfBirthError,
                    });
                  }
                  return (
                    <Box mb={4}>
                      <DateOfBirthInput
                        placeholder={{ day: 'DD', month: 'MM', year: 'YYYY' }}
                        onBlur={(date) => {
                          setValue(field.name, date, {
                            shouldValidate: true,
                          });
                        }}
                        onChange={(date) => {
                          setValue(field.name, date, {
                            shouldValidate: false,
                          });
                          gtmTrackEvent({
                            event: Events.FORM_INTERACTION,
                            form_field_name:
                              SaveAndReturnFormFields.DateOfBirth,
                          });
                        }}
                        isInvalid={!!error}
                      />
                      {error && (
                        <ValidationMessage
                          message="Please enter a valid date"
                          mt={3}
                        />
                      )}
                    </Box>
                  );
                }}
              />
            </Box>
            <Box pb={8}>
              <FormLabel
                htmlFor={SaveAndReturnFormFields.Postcode}
                color="brand.primary"
                fontWeight="semibold"
                mb={1}
              >
                Postcode
              </FormLabel>

              <Controller
                name={SaveAndReturnFormFields.Postcode}
                control={control}
                render={({ field, fieldState: { error } }) => {
                  if (error) {
                    gtmTrackEvent({
                      event: Events.FORM_ERROR,
                      form_field_error:
                        SaveAndReturnFormFieldErrors.PostcodeError,
                    });
                  }

                  return (
                    <Box mb={4}>
                      <Input
                        id={SaveAndReturnFormFields.Postcode}
                        {...field}
                        className="plum-mouseflow-hidden"
                        value={field.value || ''}
                        isInvalid={!!error}
                        fontWeight={600}
                        py={2.5}
                        onFocus={() => {
                          gtmTrackEvent({
                            event: Events.FORM_INTERACTION,
                            form_field_name: SaveAndReturnFormFields.Postcode,
                          });
                        }}
                      />
                      {error?.message && (
                        <ValidationMessage message={error.message} mt={3} />
                      )}
                    </Box>
                  );
                }}
              />
            </Box>
            <Box pb={8}>
              <Button type="submit" variant="primary" isFullWidth>
                Log in to my application
              </Button>
            </Box>
          </form>
        </ContentWrapper>
      </ResponsivePageLayout>
      <Loading
        isLoading={isSaveAndReturnLoading || isRedirectLoading}
        title="Retrieving your application"
        secondsToLoad={20}
      />
    </>
  );
};
